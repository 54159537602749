const baseBreadcrumbs = (child) => {
  const breadcrumbs = [{ label: 'Dashboards' }]
  if (child) breadcrumbs.push(child)
  return breadcrumbs
}

export default [{
  path: '/v2/dashboards/atendimentos',
  name: 'route_dashboards_atendimentos',
  component: () => import('@/modules/dashboards/views/DashboardView.vue'),
  meta: {
    title: 'Dashboard de Atendimentos',
    breadcrumbs: baseBreadcrumbs({ label: 'Atendimentos' })
  }
}, {
  path: '/v2/dashboards/two',
  name: 'dashtwo',
  component: () => import('@/modules/dashboards/views/DashTwoView.vue')
}, {
  path: '/v2/dashboards/:pathMatch(.*)*',
  redirect: { path: '/v2/dashboards/atendimentos' }
}]
