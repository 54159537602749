<script setup>
import { onMounted, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import MainLayout from '../layouts/MainLayout'

const router = useRouter()

const onMessage = (data) => {
  if (data.event !== 'CLIENT_MENU_ROUTE') return
  if (data.payload.href) router.push(data.payload.href)
}

onMounted(() => window.broker.on('message', onMessage))
onUnmounted(() => window.broker.off('message', onMessage))

</script>

<template>
  <MainLayout />
</template>
