<template>
  <svg
    :width="props.width"
    :height="props.height"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 0H1.33329C1.15648 0 0.986913 0.0702379 0.861888 0.195262C0.736864 0.320286 0.666626 0.489856 0.666626 0.666667V2.39333C0.666626 2.742 0.808626 3.08467 1.05529 3.33133L4.66663 6.94267V12C4.66676 12.1136 4.69588 12.2253 4.75123 12.3244C4.80657 12.4236 4.88632 12.507 4.98292 12.5668C5.07952 12.6265 5.18977 12.6606 5.30323 12.6659C5.41669 12.6711 5.52961 12.6473 5.63129 12.5967L8.29796 11.2633C8.52396 11.15 8.66663 10.9193 8.66663 10.6667V6.94267L12.278 3.33133C12.5246 3.08467 12.6666 2.742 12.6666 2.39333V0.666667C12.6666 0.489856 12.5964 0.320286 12.4714 0.195262C12.3463 0.0702379 12.1768 0 12 0ZM7.52863 6.19533C7.4666 6.25714 7.41741 6.3306 7.38388 6.41149C7.35036 6.49238 7.33317 6.5791 7.33329 6.66667V10.2547L5.99996 10.9213V6.66667C6.00009 6.5791 5.98289 6.49238 5.94937 6.41149C5.91585 6.3306 5.86665 6.25714 5.80463 6.19533L1.99996 2.39333V1.33333H11.334L11.3353 2.38867L7.52863 6.19533Z"
      :fill="props.color"
    />
  </svg>
</template>

<script setup>

const props = defineProps({
  color: { type: String, default: () => 'white' },
  width: { type: String, default: () => '16' },
  height: { type: String, default: () => '16' }
})
</script>
