<script setup>
import { ref, watch, onBeforeMount } from 'vue'
import { useRoute } from 'vue-router'

const breadcrumb = ref([])
const title = ref('')
const route = useRoute()

onBeforeMount(() => {
  mountBreadcrumbAndTitle(route.path)
})

const mountBreadcrumbAndTitle = (path) => {
  breadcrumb.value = route.meta.breadcrumbs
  title.value = route.meta.title
}

watch(() => route.path, (newPath) => {
  mountBreadcrumbAndTitle(newPath)
}, { immediate: true })

</script>

<template>
  <div class="font-work-sans breadcrumb-container">
    <span class="breadcrumb-title-font">{{ title }}</span>
    <div class="breadcrumb-item">
      <div
        v-for="(crumb, index) in breadcrumb"
        :key="index"
        :label="crumb.label"
      >
        <span class="breadcrumb-text-font">{{ crumb.label }}</span>
        <q-icon
          v-if="index < breadcrumb.length - 1"
          size="20px"
          name="mdi-chevron-right"
          color="primary"
        />
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
.breadcrumb-container
  padding: 4px 0 4px 16px

.breadcrumb-item
  display: flex
  flex-direction: row

.breadcrumb-title-font
  font-size: 16px
  font-family:"Work-sans-semi-bold"
  color: #263238

.breadcrumb-text-font
  font-size: 13px
  color: #065AA1
</style>
