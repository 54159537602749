<template>
  <svg
    :width="props.width"
    :height="props.height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >

    <path
      d="M12 16L16 11H13V4H11V11H8L12 16Z"
      fill="#8F8F8F"
    />
    <path
      d="M20 18H4V11H2V18C2 19.103 2.897 20 4 20H20C21.103 20 22 19.103 22 18V11H20V18Z"
      fill="#8F8F8F"
    />

  </svg>
</template>

<script setup>
const props = defineProps({
  color: { type: String, default: () => 'white' },
  width: { type: String, default: () => '16' },
  height: { type: String, default: () => '16' }
})
</script>
