import { h, createApp } from 'vue'
import singleSpaVue from 'single-spa-vue'
import App from '@/modules/app/App.vue'
import createRouter from '@/router'
import quasarUserOptions from '@/quasar'
import Quasar from 'quasar/src/vue-plugin.js';import Dialog from 'quasar/src/plugins/dialog/Dialog.js';import Notify from 'quasar/src/plugins/notify/Notify.js';
import lang from 'quasar/lang/pt-BR.js'
import { createPinia } from 'pinia'
import '@/styles/app.scss'

const router = createRouter()
const pinia = createPinia()

const vueLifecycles = singleSpaVue({
  createApp,

  appOptions: {
    render () {
      return h(App)
    }
  },

  handleInstance (app) {
    app.use(router)
    app.use(pinia)
    app.use(
      Quasar,
      {
        lang,
        plugins: {
          Dialog,
          Notify
        }
      },
      quasarUserOptions
    )
  }
})

export const bootstrap = vueLifecycles.bootstrap
export const mount = vueLifecycles.mount
export const unmount = vueLifecycles.unmount
