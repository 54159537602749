import './styles/quasar.sass'
import '@quasar/extras/mdi-v4/mdi-v4.css'
import 'material-icons/iconfont/material-icons.css'
import 'material-icons/iconfont/outlined.css'

export default {
  config: {},
  framework: {
    plugins: [
      'Dialog'
    ]
  }
}
