const baseBreadcrumbs = (child) => {
  const breadcrumbs = [{ label: 'Chamadas' }]
  if (child) breadcrumbs.push(child)
  return breadcrumbs
}

export default [{
  path: '/v2/chamadas/por-causa',
  name: 'route_call_by_hangup_cause',
  component: () => import('@/modules/calls/views/CallByHangupCause.vue'),
  meta: {
    title: 'Chamadas por Causa de Desligamento',
    breadcrumbs: baseBreadcrumbs({ label: 'Por Causa' }),
    showFilterButton: true,
    showExportButton: true
  }
}]
